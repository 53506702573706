import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import StackWithCircularProgres from "../utils/StackWithCircularProgres";
import SubscriptionErrorAlert from "../utils/SubscriptionErrorAlert";
import { Link } from "gatsby";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { subsTrans as enSubsTrans } from "../../../locales/en/subscriptions";
import { subsTrans as plSubsTrans } from "../../../locales/pl/subscriptions";
import { commonTrans as enCommonTrans } from "../../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../../locales/pl/common";

i18next.addResourceBundle("en", "subscriptions", enSubsTrans);
i18next.addResourceBundle("pl", "subscriptions", plSubsTrans);
i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

export default function LayoutChildrenBox({
  children,
  subscriptionError,
  subscriptionLoading,
  isSubOverrun,
  activeSubscriptionCode,
}) {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: "calc(100vh - 132px)",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {subscriptionLoading ? (
            <StackWithCircularProgres />
          ) : (
            <>
              {subscriptionError && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid px={2}>
                      <SubscriptionErrorAlert message={subscriptionError} withButton={true} />
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </>
          )}
          {isSubOverrun && <SubOverrunAlert activeSubscriptionCode={activeSubscriptionCode} />}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const SubOverrunAlert = ({ activeSubscriptionCode }) => {
  const { i18n } = useTranslation();
  const { t } = i18n;
  return (
    <Grid item xs={12}>
    <Stack mt={2} spacing={2}>
      <Alert
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #7a0000",
          borderRadius:  "8px",
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
        action={
          <Grid container>
            <Button
              color="inherit"
              size="small"
              component={Link}
              to={`/dashboard/subscriptions/${activeSubscriptionCode}`}
            >
              {t("common:details", "Details")}
            </Button>
          </Grid>
        }
        severity={"warning"}
      >
        <AlertTitle>
          <Typography
            color="inherit"
            variant="outlined"
            size="small"
            component={Link}
            to={`/dashboard/subscriptions/${activeSubscriptionCode}`}
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("subscriptions:overrun_title", "The limits applicable to your subscription plan have been exceeded.")}
          </Typography>
        </AlertTitle>
        <Typography align="left" variant="caption" color="info">
          {t("subscriptions:overrun_subtitle", "Please consider changing your subscription plan or adjusting your account to meet the requirements of your plan.")}
        </Typography>
      </Alert>
    </Stack>
  </Grid>
  )
}