import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Menu, MenuItem, Typography } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { navigate } from "gatsby";
import { CHANGE_TEAM, GET_ACTIVE_SUBSCRIPTION } from "../../constants";

import i18next, { t } from "i18next";

import { teamTrans as enTeamTrans } from "../../../locales/en/team";
import { teamTrans as plTeamTrans } from "../../../locales/pl/team";
import { layoutTrans as enLayoutTrans } from "../../../locales/en/layout";
import { layoutTrans as plLayoutTrans } from "../../../locales/pl/layout";
import { getTeamShortName } from "../teams/getTeamShortName";

i18next.addResourceBundle("en", "layout", enLayoutTrans);
i18next.addResourceBundle("pl", "layout", plLayoutTrans);
i18next.addResourceBundle("en", "team", enTeamTrans);
i18next.addResourceBundle("pl", "team", plTeamTrans);

function mapStateToProps(state) {
  return {
    getUser: state.getUser,
    changeCurrentTeamContext: state.changeCurrentTeamContext,
  };
}

const ChangeTeamContextMenu = ({
  actions,
  changeCurrentTeamContext,
  user,
  changeTeamContextMenuOpen,
  changeTeamContextMenuAnchorEl,
  setChangeTeamContextMenuAnchorEl,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (changeCurrentTeamContext) {
      if (window.location.pathname === "/dashboard/") {
        window.location.reload();
      } else {
        navigate("/dashboard");
        actions.clearState(CHANGE_TEAM);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCurrentTeamContext]);

  const handleClose = () => {
    setChangeTeamContextMenuAnchorEl(null);
    setDisableButton(false);
  };

  const toggleCurrentTeamContext = (team) => {
    setDisableButton(true);
    actions.changeCurrentTeamContext({ code: team.code });
    actions.clearState(GET_ACTIVE_SUBSCRIPTION);
  };
  return (
    <Menu
      id="menu"
      aria-labelledby="menu-open-button"
      anchorEl={changeTeamContextMenuAnchorEl}
      open={changeTeamContextMenuOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      maxWidth="md"
    >
      {user?.teams?.map((team) => (
        <MenuItem
          key={team.code}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          onClick={() => toggleCurrentTeamContext(team)}
          disabled={user.currentTeamCodeContext === team.code || disableButton}
        >
          <Typography sx={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
            {getTeamShortName(team)}{" "}
            {team.type === "individual" && `(${t("team:type_individual", "personal profile")})`}
          </Typography>
          <WorkIcon fontSize="large" sx={{ paddingLeft: "8px" }} />
        </MenuItem>
      ))}
    </Menu>
  );
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTeamContextMenu);
