export const subsTrans = {
  title: "Subskrypcje",
  singleTitle: "Subskrypcja",
  orders: "Zamówienia",
  details: "Dane szczegółowe subskrypcji",
  from: "Od",
  to: "Do",
  api_docs: "dokumentacja",
  overrun_title: "Limity obowiązujące w Twoim planie subskrypcji zostały przekroczone.",
  overrun_subtitle: "Proszę rozważyć zmianę planu subskrypcji lub dostosowanie swojego konta do wymogów planu.",
  upgrade_plan: {
    title: "Zwiększ swoje limity i korzyści natychmiastowo przechodząc na wyższy pakiet",
    caption: "Aktualizacja obecnego planu zakończy obecnie trwającą subskrypcję. Podczas aktualizacji subskrypcji zostanie przyznany rabat za niewykorzystane pełne dni obecnego planu. Opłacone subskrypcje oczekujące na rozpoczęcie zostaną anulowane, środki zostaną zwrócone. Wszystkie zmiany wejdą w życie natychmiast po zaksięgowaniu płatności.",
    choose_plan: "Wybierz plan",
    currently_on_premium: "Aktualnie używasz planu Premium.",
    contact_us: "Aby dowiedzieć się więcej o planie Enterprise, skontaktuj się z nami.",
    contact_button_label: "Kontakt",
    sub_was_upgraded: "Subskrypcja została zaktualizowana na wyższy plan.",
    sub_was_upgraded_caption: "Subskrypcja nie jest już aktywna.",
    sub_will_be_refunded: "Subskrypcja została anulowana z powodu aktualizacji aktywnego planu. Nigdy nie została uruchomiona.",
    sub_refund_caption: "Pieniądze zostaną zwrócone metodą płatności użytą przy zakupie w ciągu 3 dni roboczych od anulowania subskrypcji. W razie wątpliwości prosimy o kontakt.",
  },
  usage: {
    title: "Użycie",
    current_period: "Aktualny okres rozliczeniowy",
    parties_number: "Liczba podmiotów",
    transactions_creations: "Tworzenie transakcji",
    sanction_searches: "Wyszukiwania na listach sankcyjnych",
    team_members_number: "Liczba członków zespołu",
  },
  features: {
    api_access: "Dostęp API",
    kyc_access: "Dostęp KYC",
  },
  table: {
    code: "Kod",
    valid_from: "Ważny od",
    valid_to: "Ważny do",
    status: {
      title: "Status",
      active: "Aktywny",
      payment_received: "Opłacony",
      upgraded: "Zaktualizowany",
      cancelled_after_payment_received: "Anulowany",
    },
    plan: {
      title: "Plan",
      free: "Free",
      trial: "Trial",
      starter: "Starter",
      minimum: "Minimum",
      standard: "Standard",
      premium: "Premium",
    },
  },
};
