import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import { ChevronLeft } from "@mui/icons-material";
import { Divider, Drawer, IconButton, List, Toolbar } from "@mui/material";
import { MainNavItems, SecondaryNavItems } from "./navItems";

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function LayoutResponsiveDrawer({ user, open, setOpen, toggleDrawer, drawerWidth, isMobile }) {

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: `${drawerWidth}px`,
            bgcolor: "background.drawer",
          },
        }}
      >
        <DrawerContent toggleDrawer={toggleDrawer} user={user} onButtonClick={() => setOpen(false)}/>
      </Drawer>
    )}

  return (
    <StyledDrawer variant="permanent" open={open} drawerWidth={drawerWidth}>
      <DrawerContent toggleDrawer={toggleDrawer} user={user} />
    </StyledDrawer>
  );
}

const DrawerContent = ({ user, toggleDrawer, onButtonClick = () => {} }) => (
  <>
    <Toolbar
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        px: [1],
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeft />
      </IconButton>
    </Toolbar>
    <Divider />
    <List>
      <MainNavItems
        newAlertsAmount={user.newAlertsAmount}
        newTasksAmount={user.newTasksAmount}
        user={user}
        onButtonClick={onButtonClick}
        // user={props.getUser?.data}
      />
    </List>
    <Divider />
    <List>
      <SecondaryNavItems giif={user.giif} onButtonClick={onButtonClick}/>
    </List>
  </>
);
