export const teamTrans = {
  title: "Zespoły",
  team_details: "Szczegóły zespołu",
  page_title: "Zespół",
  team_members: "Członkowie zespołu",
  invite: "Zaproś",
  inviting_team_member: "Zaproś członka zespołu",
  team_successfully_deleted: "Zespół został pomyślnie usunięty",
  table: {
    code: "Kod",
    email: "Email",
    status: "Status",
    role: "Rola",
    added: "Dodany",
    created_at: "Data utworzenia",
    expires_at: "Data wygaśnięcia",
    team_name: "Nazwa zespołu",
    invited_email: "Zapraszany użytkownik",
    user_that_invites: "Użytkownik, który zaprasza",
    count_members: "Liczba członków zespołu",
    admin: "Administrator",
  },
  status: {
    active: "Oczekuje na akceptację",
    rejected: "Odrzucone",
    fulfilled: "Zaakceptowane",
    expired: "Wygasło",
  },
  roles: {
    all_viewer_member: "Dostęp do wszystkich zasobów",
    all_viewer_member_self_editing: "Dostęp do wszystkich zasobów z możliwością edycji własnych",
    all_viewer_member_self_editing_time_limited: "Dostęp do wszystkich zasobów z możliwością edycji własnych przez ograniczony czas (12h)",
    self_viewer_member: "Dostęp do własnych zasobów",
    admin: "Administrator",
  },
  form: {
    title: "Utwórz zespół",
    info: "Aby w pełni korzystać z nowo utworzonego zespołu niezbędne jest wykupienie oddzielnej subskrypcji dla tego zespołu",
    create_new: "Utwórz nowy",
    create_success: "Zespół {{title}} został utworzony",
    email: {
      label: "Adres email",
      error: "",
    },
    role: {
      label: "Rola",
      error: "",
    },
    new_role: {
      label: "Nowa rola",
      error: "",
    },
    name: {
      label: "Nazwa",
      error: "Nazwa zespołu nie może być dłuższa niż 70 znaków",
    },
    invitation_send: "Invitation has been send",
  },
  you_were_invited: "Zostałeś(aś) zaproszony do zespołu",
  by: "przez",
  register_to_join: "Załóż konto aby dołączyć",
  received_team_invitations: "Otrzymane zaproszenia do zespołu",
  sended_team_invitations: "Wysłane zaproszenia do zespołu",
  no_new_invitations: "Brak aktywnych zaproszeń do zespołu",
  handle_team_invitation_title: "Zaproszenie do zespołu {{code}}",
  join_to_team:
    "Dołączenie do nowego zespołu nie będzie miało wpływu na dostęp do Twoich obecnych danych. W każdej chwili można przełączyć profil z jakiego korzystasz.",
  type_individual: "profil osobisty",
  invitation_delete_question: "Czy na pewno chcesz usunąć zaproszenie do zespołu {{code}}?",
  delete_question: "Czy na pewno chcesz usunąć zespół {{code}}?",
  edit_name_success: "Nazwa zespołu została zmieniona",
  confirm: "Potwierdź",
  edit_team_member: "Edycja roli członka zespołu",
  delete_team_member_question: "Czy na pewno chcesz usunąć członka zespołu {{email}}?",
  change_context: "Przełącz kontekst",
  already_using_this_team: "Aktualnie korzystasz z SystemAML w kontekście tego zespołu",
  change_team_context: "Przełącz kontekst korzystania z SystemAML na wyświetlany zespół",
  leave: "Opuść zespół",
  leave_team_question: "Czy na pewno chcesz opuścić zespół?",
  not_available_with_this_current_team: "Aby przejść dalej należy zmienić kontekst w którym korzystasz z SystemAML",
  admin_required: "Aby kontynuować, wymagana jest rola użytkownika administratora",
};
