export const teamTrans = {
  title: "Teams",
  team_details: "Team details",
  page_title: "Team",
  team_members: "Team members",
  invite: "Invite",
  inviting_team_member: "Invite team member",
  team_successfully_deleted: "Team has been successfully deleted",
  table: {
    code: "Code",
    email: "Email",
    status: "Status",
    role: "Role",
    added: "Added",
    created_at: "Creation date",
    expires_at: "Expiration date",
    invited_email: "Invited email",
    team_name: "Team name",
    user_that_invites: "User who invites",
    count_members: "Team members count",
    admin: "Admin",
  },
  status:{
    active: "Waiting for acceptance",
    rejected: "Rejected",
    fulfilled: "Accepted",
    expired: "Expired",
  },
  roles: {
    all_viewer_member: "All viewer member",
    all_viewer_member_self_editing: "All viewer member with self editing",
    all_viewer_member_self_editing_time_limited: "All viewer member with self editing for limited time (12h)",
    self_viewer_member: "Self viewer member",
    admin: "Admin",
  },
  form: {
    title: "Create a team",
    info: "To take full advantage of the newly created team, it is necessary to purchase a separate subscription for this team",
    create_new: "Create new",
    create_success: "The team {{title}} has been created",
    email: {
      label: "Address email",
      error: ""
    },
    role: {
      label: "Role",
      error: ''
    },
    new_role: {
      label: "New role",
      error: ''
    },
    name: {
      label: "Name",
      error: "The team name must not be longer than 70 characters"
    },
  },
  you_were_invited: "You have been invited to join the team",
  by: "by",
  register_to_join: "register an account to join",
  received_team_invitations: "Received team invitations",
  sended_team_invitations: "Sended team invitations",
  no_new_invitations: "No active invitations to join the team",
  handle_team_invitation_title: "Team invitation {{code}}",
  join_to_team: "Joining a new team will not affect access to your current data. You can switch the profile you are using at any time.",
  type_individual: "personal profile",
  invitation_delete_question: "Are you sure you want to delete team invitation {{code}}",
  delete_question: "Are you sure you want to delete team {{code}}",
  edit_name_success: "Team name successfully edited",
  confirm: "Confirm",
  edit_team_member: "Edit team member role",
  delete_team_member_question: "Are You sure You want to remove team member {{email}} ?",
  change_context: "Change context",
  already_using_this_team: "You are currently using SystemAML in the context of this team",
  change_team_context: "Switch the context of using SystemAML to the displayed team",
  leave: "Leave the team",
  not_available_with_this_current_team: "To continue, change the context in which you are using SystemAML",
  admin_required: "To continue, admin user role is required",
  leave_team_question: "Are You sure you want to leave the team?"
}
