import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";

import {Box, CssBaseline, Divider, useMediaQuery, useTheme} from "@mui/material";

import LayoutAppBar from "./LayoutAppBar.js";
import LayoutChildrenBox from "./LayoutChildrenBox.js";
import LayoutResponsiveDrawer from "./LayoutResponsiveDrawer.js";
import Footer from "../../components/footer.js";

import i18next from "i18next";
import { commonTrans as enCommonTrans } from "../../../locales/en/common";
import { commonTrans as plCommonTrans } from "../../../locales/pl/common";

i18next.addResourceBundle("en", "common", enCommonTrans);
i18next.addResourceBundle("pl", "common", plCommonTrans);

function mapStateToProps(state) {
  return {
    getUser: state.getUser,
    getActiveSubscription: state.getActiveSubscription,
  };
}

const drawerWidth = 240;

function Layout(props) {
  const initialDrawerPosition =
    (typeof window !== "undefined" && window.localStorage.getItem("drawerPosition")) || "open";

  const [cartItemsNumber, setCartItemsNumber] = useState(
    (typeof window !== "undefined" && window.localStorage.getItem("cartItemsNumber")) || 0
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = useState(initialDrawerPosition === "open");
  const [user, setUser] = useState({
    email: "",
    newAlertsAmount: 0,
    newTasksAmount: 0,
    giif: false,
    roles: [],
  });

  const [activeSubscription, setActiveSubscription] = useState(false);
  const [isSubOverrun, setIsSubOverrun] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [currentContextTeam, setCurrentContextTeam] = useState({name: null, type: 'individual'});

  useEffect(() => {
    if (props.location.pathname.substring(0, 10) === "/dashboard") {
      props.actions.getActiveSubscription();
      props.actions.userCheckLoggedIn();
      if (props.getUser === null) {
        props.actions.getUser();
      }
    }
    const updateCart = (event) => {
      setCartItemsNumber(event.detail);
    };
    window.addEventListener("updateCart", updateCart);

    return () => {
      window.removeEventListener("updateCart", updateCart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.location.state?.checkLogin === true) {
      props.actions.getActiveSubscription();
      props.actions.userCheckLoggedIn();
      if (props.getUser === null) {
        props.actions.getUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  useEffect(() => {
    if (props.getUser) {
      if (props.getUser.data) {
        const {data} = props.getUser;
        setUser(data)
        const currentTeam = data.teams.find((team) => team.code === data.currentTeamCodeContext)
        setCurrentContextTeam(currentTeam)
      }
    }
  }, [props.getUser]);

  useEffect(() => {
    if (props.getActiveSubscription !== null) {
      setSubscriptionLoading(false);
      props.actions.toggleSubscription(true);
      if (props.getActiveSubscription.error) {
        setSubscriptionError(props.getActiveSubscription.error);
        props.actions.toggleSubscription(false);
      }
      if (props.getActiveSubscription.data) {
        setActiveSubscription(props.getActiveSubscription.data);
        setIsSubOverrun(props.getActiveSubscription.data.isOverrun);
        setSubscriptionError(false);
        props.actions.toggleSubscriptionOverrun(props.getActiveSubscription.data.isOverrun);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getActiveSubscription]);

  const toggleDrawer = () => {
    setOpen((prev) => {
      const newState = !prev;
      localStorage.setItem("drawerPosition", newState ? "open" : "mid");
      return newState;
    });
  };

  if (props.location.pathname.substring(0, 10) !== "/dashboard") {
    return props.children;
  }
  if (props.children.key === "/404.html") {
    return props.children;
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <LayoutAppBar
          open={open}
          toggleDrawer={toggleDrawer}
          user={user}
          currentContextTeam={currentContextTeam}
          cartItemsNumber={cartItemsNumber}
          setSubscriptionError={setSubscriptionError}
          isMobile={isMobile}
          drawerWidth={drawerWidth}
        />

        <LayoutResponsiveDrawer
          open={open}
          setOpen={setOpen}
          drawerWidth={drawerWidth}
          user={user}
          isMobile={isMobile}
          toggleDrawer={toggleDrawer}
        />

        <LayoutChildrenBox
          children={props.children}
          subscriptionError={subscriptionError}
          subscriptionLoading={subscriptionLoading}
          isSubOverrun={isSubOverrun}
          activeSubscriptionCode={activeSubscription?.code}
        />
      </Box>
      <Divider />
      <Footer sx={{ pt: 4 }} />
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
