export const attachmentsTrans = {
  ft: {
    attachments: "Załączniki",
    attachment: "Załącznik",
    attachments_not_found: "Nie znaleziono załączników",
    connect_drive: "Połącz swój dysk",
    no_drive_connected: "Żaden dysk nie jest podłączony",
    drive_settings: "Ustawienia dysku",
    drive_attachments_requirements:
      "Dodawanie załączników jest dostępne po połączeniu z dyskiem. Możesz to zrobić w ustawieniach",
    attachments_google_drive: "Załączniki Google Drive",
    attachments_one_drive: "Załączniki Microsoft Drive",
    attachment_removed: "Załącznik usunięty",
    attachment_added: "Załącznik dodany",
    description_updated: "Opis został zaktualizowany",
    add_attachment: "Dodaj załącznik",
    remove_attachment: "Usuń załącznik",
    remove_attachment_question: "Czy na pewno chcesz usunąć załącznik: {{fileName}}?",
    file_not_selected: "Nie wybrano pliku",
    select_file: "Wybierz plik",
    send: "Wyślij",
    change_description: "Zmień opis",
    without_description: "Bez opisu",
    max_size: "Maksymalny rozmiar pliku to ",

    table: {
      name: "Nazwa",
      description: "Opis",
      created_time: "Data dodania",
    },

    validation: {
      max_length: "Maksymalna długość opisu to 255 znaków",
    },
  },
};
