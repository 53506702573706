export const subsTrans = {
  title: "Subscriptions",
  singleTitle: "Subscription",
  orders: "Orders",
  details: "Details",
  from: "From",
  to: "To",
  api_docs: "documentation",
  overrun_title: "The limits applicable to your subscription plan have been exceeded.",
  overrun_subtitle: "Please consider changing your subscription plan or adjusting your account to meet the requirements of your plan.",
  upgrade_plan: {
    title: "Increase your limits and benefits instantly by upgrading to a higher package",
    caption: "Upgrading the current plan will terminate the subscription currently in progress. During the subscription upgrade, a discount will be given for unused whole days of the current plan. Paid subscriptions waiting to start will be canceled, funds will be refunded. All changes will take effect immediately after payment is posted.",
    currently_on_premium: "You currently use a Premium plan.",
    contact_us: "To learn more about the Enterprise plan, please contact us.",
    contact_button_label: "Contact",
    sub_was_upgraded: "The subscription was upgraded to the higher plan.",
    sub_was_upgraded_caption: "The subscription is not longer active.",
    sub_will_be_refunded: "The subscription was canceled due to an update of the active plan. It has never been activated.",
    sub_refund_caption: "The money will be refunded to the payment method used at the time of purchase within 3 working days after cancelling the subscription. If in doubt, please contact us.",
  },
  usage: {
    title: "Usage",
    current_period: "Current usage period",
    parties_number: "Parties number",
    transactions_creations: "Transactions creations",
    sanction_searches: "Sanction searches",
    team_members_number: "Team members number",
  },
  features: {
    api_access: "API access",
    kyc_access: "KYC access",
  },
  table: {
    code: "Code",
    valid_from: "Valid from",
    valid_to: "Valid to",
    status: {
      title: "Status",
      active: "Active",
      payment_received: "Payment received",
      upgraded: "Upgraded",
      cancelled_after_payment_received: "Cancelled",
    },
    plan: {
      title: "Plan",
      free: "Free",
      tria: "Trial",
      starter: "Starter",
      minimum: "Minimum",
      standard: "Standard",
      premium: "Premium",
    },
  },
};
