import React, { useState } from "react";

import { Grid, IconButton, Toolbar, Badge, Button, Typography } from "@mui/material";
import { AccountCircle, Menu, Work, Settings, ShoppingCartCheckout } from "@mui/icons-material";
import MuiAppBar from "@mui/material/AppBar";

import { styled } from "@mui/material/styles";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import ChangeTeamContextMenu from "./ChangeTeamContextMenu";
import TeamMenu from "./TeamMenu";
import UserMenu from "./UserMenu";
import { getTeamShortName } from "../teams/getTeamShortName";


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isMobile, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function LayoutAppBar({
  open,
  toggleDrawer,
  user,
  currentContextTeam,
  cartItemsNumber,
  setSubscriptionError,
  isMobile,
  drawerWidth,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [teamMenuAnchorEl, setTeamMenuAnchorEl] = useState(null);
  const [changeTeamContextMenuAnchorEl, setChangeTeamContextMenuAnchorEl] = useState(null);

  const menuOpen = Boolean(anchorEl);
  const teamMenuOpen = Boolean(teamMenuAnchorEl);
  const changeTeamContextMenuOpen = Boolean(changeTeamContextMenuAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenChangeTeamContextMenu = (e) => {
    setChangeTeamContextMenuAnchorEl(e.currentTarget);
  };
  const handleOpenTeamMenu = (e) => {
    setTeamMenuAnchorEl(e.currentTarget);
  };

  return (
    <AppBar position="absolute" open={open} isMobile={isMobile} drawerWidth={drawerWidth} color="default">
      <Toolbar sx={{ pr: "24px" }}>
        <IconButton
          edge="start"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: { xs: "8px", sm: "32px" },
            ...(open && !isMobile && { display: "none" }),
          }}
        >
          <Menu />
        </IconButton>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            sx={{
              display: (theme) => (theme.palette.mode === "light" ? "none" : "display"),
              width: { xs: "150px", sm: "200px" },
            }}
          >
            <Link to="/dashboard/">
              <StaticImage src="../../images/SystemAMLlight.png" alt="System AML" placeholder="none" quality="100" />
            </Link>
          </Grid>
          <Grid
            item
            sx={{
              display: (theme) => (theme.palette.mode === "dark" ? "none" : "display"),
              width: { xs: "150px", sm: "200px" },
            }}
          >
            <Link to="/dashboard/">
              <StaticImage src="../../images/SystemAMLdark.png" alt="System AML" placeholder="none" quality="100" />
            </Link>
          </Grid>

          <Grid item>
            {currentContextTeam?.admin && cartItemsNumber > 0 && (
              <IconButton component={Link} to="/dashboard/cart" sx={{ mr: 1 }}>
                <Badge showZero badgeContent={cartItemsNumber} color="secondary">
                  <ShoppingCartCheckout color="primary" />
                </Badge>
              </IconButton>
            )}

            <Button
              // color="primary"
              id="menu-open-button"
              aria-controls={menuOpen ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleOpenChangeTeamContextMenu}
              sx={{ maxWidth: { sm: "210px", md: "300px", lg: "500px" } }}
            >
              <Typography variant="h7" display={{ xs: "none", sm: "block" }}>
                {getTeamShortName(currentContextTeam ? currentContextTeam : { name: null, type: "individual" })}
              </Typography>
              <Work fontSize="large" sx={{ paddingLeft: "8px" }} />
            </Button>
            <IconButton onClick={handleOpenTeamMenu}>
              <Settings />
            </IconButton>
            <IconButton onClick={handleClick}>
              <AccountCircle color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
        <ChangeTeamContextMenu
          user={user}
          changeTeamContextMenuOpen={changeTeamContextMenuOpen}
          changeTeamContextMenuAnchorEl={changeTeamContextMenuAnchorEl}
          setChangeTeamContextMenuAnchorEl={setChangeTeamContextMenuAnchorEl}
        />
        <TeamMenu
          user={user}
          isAdmin={currentContextTeam?.admin}
          isCurrentTeam={currentContextTeam?.code === user?.currentContextTeam}
          teamMenuOpen={teamMenuOpen}
          teamMenuAnchorEl={teamMenuAnchorEl}
          setTeamMenuAnchorEl={setTeamMenuAnchorEl}
        />
        <UserMenu
          user={user}
          handleClick={handleClick}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuOpen={menuOpen}
          setSubscriptionError={() => setSubscriptionError(false)}
        />
      </Toolbar>
    </AppBar>
  );
}
